import React from "react";
import Card from "../Card";
import InlineSelect from "../Inputs/InlineSelect";
import { InputGroup, TextInput } from "../Inputs";

export const DEFAULT_TIP_OPTIONS = [10, 15, 20, 25];
export const CUSTOM_TIP_OPTION_VALUE = "custom";
export const SET_TIP_LABEL = "Set Tip";

const TipSelection = ({
  appStyles,
  T,
  selectedServingOption,
  currentBranch,
  businessAppConfiguration,
  order,
  currencySymbol,
  setDeliveryTipPercentage,
  setServingOptionTipPercentage,
  setDeliveryTipCustomValue,
  setServingOptionTipCustomValue,
}) => {

  const offerDeliveryTip =
  selectedServingOption.needsAddress &&
  _.get(businessAppConfiguration, "offersDeliveryTip") &&
  !_.get(currentBranch, "disableDeliveryTip");

const servingOptionOffersTip =
  selectedServingOption.offersTip &&
  !_.includes(
    _.get(currentBranch, "tipDisabledServingOptionTypes"),
    selectedServingOption.type,
  );


const enableCustomTip = _.get(currentBranch, "enableCustomTip");
const servingOptionOffersNoTip =
  selectedServingOption.offersTip &&
  !_.includes(
    _.get(currentBranch, "noTipDisabledServingOptionTypes"),
    selectedServingOption.type,
  );


const tipOptions = _.compact([
  servingOptionOffersNoTip ? { label: T("No Tip"), value: 0 } : null,
  ..._.map(
    _.get(
      currentBranch,
      `servingOptionTypeToTipOptions.${selectedServingOption.type}`,
      DEFAULT_TIP_OPTIONS,
    ),
    (option) => ({
      label: `${option}%`,
      value: option / 100,
    }),
  ),
  enableCustomTip && {
    value: CUSTOM_TIP_OPTION_VALUE,
    label: T(SET_TIP_LABEL),
  },
]);

  return (
    <Card appStyles={appStyles}>
      <Card.Title light appStyles={appStyles}>
        {offerDeliveryTip
          ? T("Driver Tip")
          : `${T(`${T(selectedServingOption.servingOptionTag)} Tip`)}`}
      </Card.Title>
      <Card.Content>
        <small>
          {offerDeliveryTip
            ? T("Order price does not include driver's tip")
            : T("Order price does not include gratuity")}
        </small>
      </Card.Content>
      <InputGroup tight appStyles={appStyles} T={T}>
        <InlineSelect
          rtl={appStyles.rtl}
          options={tipOptions}
          value={
            offerDeliveryTip
              ? order.deliveryTipPercentage
              : order.servingOptionTipPercentage
          }
          onChange={(value) =>
            offerDeliveryTip
              ? setDeliveryTipPercentage(value)
              : setServingOptionTipPercentage(value)
          }
          selectedColor={appStyles.actionColor}
          T={T}
        />
        {enableCustomTip &&
          (offerDeliveryTip
            ? order.deliveryTipPercentage
            : order.servingOptionTipPercentage) === CUSTOM_TIP_OPTION_VALUE && (
            <React.Fragment>
              <span>
                {T("Custom Tip amount:")} ({currencySymbol})
              </span>
              <TextInput
                pattern="/^\d{1,10}(\.\d{1,2})?$/"
                initialValue={
                  offerDeliveryTip
                    ? order.deliveryTipCustomValue
                    : order.servingOptionTipCustomValue
                }
                type="number"
                step="any"
                disabled={
                  (offerDeliveryTip
                    ? order.deliveryTipPercentage
                    : order.servingOptionTipPercentage) !== CUSTOM_TIP_OPTION_VALUE
                }
                appStyles={appStyles}
                style={{
                  width: "50%",
                  color:
                    (offerDeliveryTip
                      ? order.deliveryTipPercentage
                      : order.servingOptionTipPercentage) ===
                    CUSTOM_TIP_OPTION_VALUE
                      ? undefined
                      : "transparent",
                }}
                validator={(value) => value > 0}
                errorMessage={T(
                  `You selected ${T(SET_TIP_LABEL)}, but did not enter an amount`
                )}
                onChange={(value) =>
                  offerDeliveryTip
                    ? setDeliveryTipCustomValue(value)
                    : setServingOptionTipCustomValue(value)
                }
              />
            </React.Fragment>
          )}
      </InputGroup>
    </Card>
  );
};

export default TipSelection; 