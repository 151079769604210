import React from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import ScanQRView from "../components/ScanQRView";
import withTranslation from "../hocs/withTranslation";
import {
  login,
  resetLogin,
  loadPaymentMethods,
  loadLoyaltyProfile,
} from "../store/user/actions";
import * as s from "../store/selectors";
import withCaptcha from "../hooks/withCaptcha";

const ScanQRViewWithRecaptcha = withCaptcha(ScanQRView);

const ScanQRPage = (props) => {
  const businessAppConfiguration =
    props.pageContext.businessAppConfiguration || {};

  const {
    hcaptchaKey,
    recaptchaKey,
  } = businessAppConfiguration;

  const loadWithSecurityProvider =
    recaptchaKey || hcaptchaKey
  return loadWithSecurityProvider ? (
    <ScanQRViewWithRecaptcha {...props} />
  ) : (
    <ScanQRView {...props} />
  );
};

const mapStateToProps = (state, props) => {
  const { user, app, order } = state;

  return {
    user,
    app,
    order,
    appStyles: s.getStyles(state, props),
    // hasChargeCard: s.hasChargeCard(state, props),
    // canUserLoadChargeCard: s.canUserLoadChargeCard(state, props),
    // hasChargeCardDiscount: s.hasChargeCardDiscount(state, props),
    paymentTypeDetails: s.getDefaultPaymentMethodDetails(state, props),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const {
    pageContext: { business },
  } = props;

  const currency = get(business, "openChargeCard.price.currency", 0);

  return {
    loadPaymentMethods: (paymentTypeIdentifier) =>
      dispatch(loadPaymentMethods(paymentTypeIdentifier, currency)),
    login: (phoneNumber) => dispatch(login({ phoneNumber })),
    resetLogin: () => dispatch(resetLogin()),
    loadLoyaltyProfile: () => dispatch(loadLoyaltyProfile()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(ScanQRPage));
